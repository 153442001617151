html,body {
  height: 100%;
  background-color: rgb(245, 247, 249);
  /*overflow: hidden;*/
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

/* .search{
  padding: 10px;
  margin: 0px;
}
.search input{
  width: 100%;
  height: 20px;
  font-size: 12px;
}
 */
.searchinput{
  width:300px;
  position: absolute;
  z-index:999;
  margin:2px 0 0 2px;
}
.map-create-place{
  width: 50%;
  top: calc(50% - 50px);
  left:calc(50% /2);
  position: absolute;
  z-index: 2000;
  display:none;
}

.map-create-place h6{
  color:#212529 !important; 
  font-size: 1rem;
}

.map-create-place span{
  cursor: pointer;
  text-decoration: underline;
  color: #007bff;
  font-size: 13px;
  padding-right: 20px;
}

.map-create-place button {
	position: absolute;
	right: 0px;
	top: 0px;
	bottom: 0px;
	width: 40px;
	text-align: center;
	text-decoration: none;
	font-size: 22px;
	color: rgba(30, 136, 229, 0.6);
	transition: background-color 0.4s ease 0s;
	border: medium none;
	outline: currentcolor none medium;
	background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
  cursor: pointer;
  padding: 4px;
}

.map-create-place button:hover {
	background:#f5f3f3 none repeat scroll 0% 0%;
}


.mypos{
  bottom: 20px;
  left:20px;
  cursor:pointer;
  position: absolute;
  z-index: 1000;
}
input[type=file] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.h-100-50{
  height: calc(100% - 50px);
}

.h-70{
  height: 70%;
}

.w-100{
  width: 100%;
}

w-100{
  width: 50%;
}

.h-100-scroll{
  height:100%;
  overflow-y: auto;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}
.btn-squared{
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}
.btn-circle{
  width: 23px;
  height: 23px;
  padding: 3px 0px;
  border-radius: 10px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.stars span{
  font-size: 8px;
}
.margintop{
  margin-top:10px
}

.marginbottom{
  margin-bottom:10px
}

.absoluto{
  position: absolute;
  z-index: 1000000;
}

.starsmodal span{
  font-size: 10px;
}

.starsmodal{
  line-height:2px !important;
  padding: 0 1px 0 1px;
}
/*"white","gold","amber","red","brown", "dark"*/

.btn-white, .btn-white:hover, .btn-white:active, .btn-white:visited {
  color: #FFE681 !important;
}

.btn-gold, .btn-gold:hover, .btn-gold:active, .btn-gold:visited {
  color: #FFC625 !important;
}

.btn-amber, .btn-amber:hover, .btn-amber:active, .btn-amber:visited {
  color: #f26a37 !important;
}

.btn-red, .btn-red:hover, .btn-red:active, .btn-red:visited {
  color: #D52027 !important;
}

.btn-brown, .btn-brown:hover, .btn-brown:active, .btn-brown:visited {
  color: #A94124 !important;
}

.btn-dark, .btn-dark:hover, .btn-dark:active, .btn-dark:visited {
  color: #2E0000 !important;
  background-color: transparent;
}

.btn{
  border:0 !important;
  color:white;
}

.marginb {
  margin-left: 2px;
}

.marginp {
  margin: 5px;
}

.toastcontent{
  font-size: 13px;
}

.toastcontent .row{
  margin-top:10px;
}

.tipobar .row button,p{
  margin:0 auto;
}

.tipos_local{
  color:black;
  text-decoration: underline;
  margin-top: 100px;
}

.click{
  color:blue;
  text-decoration: underline;
  cursor: pointer;
}

.leyenda{
  font-size: 12px;
  color:#08c;
  cursor:pointer;
  line-height: 0px;
  margin-left: 5px;
}

.leyendaplus{
  font-size: 11px;
  color:#08c;
  cursor:pointer;
}

.tith5{
  font-size: 18px;
  text-decoration: bold;
}

.list-group-flush .list-group-item:first-child{
  border-top: 1px solid rgba(0,0,0,.125);
}

.card-title{
  margin-top:0;
}

h5 {
  margin-top:0px;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
  
  margin-top:20px;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.accion{
  line-height: 45px;
  cursor: pointer;
}

.list-group-item {
  background-color: transparent !important;
  border: 0 !important;
  padding:0 10px 0 0;
}
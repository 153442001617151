.place{
    cursor: pointer;
    margin-top: 5px;
    background:rgb(255, 255, 255) none repeat scroll 0% 0%;
    border-radius: 3px;
    box-shadow:
    rgba(37, 42, 49, 0.16) 0px 0px 2px 0px,
    rgba(37, 42, 49, 0.12) 0px 1px 4px 0px;
    outline: currentcolor none 0px;
    transition: box-shadow 0.15s ease-in-out 0s;
    position: relative;
    font-size: 14px;
    width:100%;
}

.placeboxselected{
    border:1px dotted grey;
}
.placebox{
    margin-bottom: 12px;
    padding:0px;
}

.placehead:hover{
    opacity: 0.8;
}

.places{
    padding-right: 5px;
    padding-left: 5px;
}


.hrsep {
    margin-top: 4px;
    margin-bottom: 4px;
    width:80%;
  }

  .placehead{
    width: 100%;
    margin-left:5px;
    margin-right: 20px;
    /*background-color: rgba(0,0,0,.03);*/
    border: 0;
    color:#212529;
    cursor:pointer;
    }

    .placeheadmodal{
        width: 100%;
        margin-left:15px;
        margin-right: 15px;
        border: 0;
        }

    .place-title{
        margin-top: auto;
        margin-bottom: auto;
    }

    .place-dist{
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 0px;
        margin-right: 0px;
        padding:0;
        color:#333;
        font-size: 12px;        
    }
    .distnone{
        display:none;
    }

    .list-group-item{
        padding:5px;
    }
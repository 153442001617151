.mapcreate{
    width: 100%;
    height: 40%;
}

.image{
    margin-bottom:20px;
}

.file_upload{
    width: 100%; height: 100px; border: 2px dashed #ddd; border-radius: 3px; cursor: pointer; text-align: center; overflow: hidden; padding: 5px; margin-top: 5px; margin-bottom : 5px; position : relative; display: flex; align-items: center; margin: auto; justify-content: center; flex-direction: column;
}

.link{
    position: absolute !important; right: 3px; top: 3px; background: rgb(237, 60, 32) none repeat scroll 0% 0%; border-radius: 3px; width: 30px; height: 30px; line-height: 30px; text-align: center; text-decoration: none; color: rgb(255, 255, 255);
}

.imagen{
    width: 64px; margin: 0px auto; vertical-align: middle; display: none;
}
.imagen2{
    width: 100%; vertical-align: middle;
}

.margin-top-20{
    margin-top:20px
}

.loading_center{
    display: flex; justify-content: center; align-items: center; height: 100vh
}

.select-S{
    width:100%;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0.2; /* Firefox */
  }

  .card-title { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color:grey;
  }

  .card{
      margin-bottom: 10px;
  }
.btn-info, .btn-info:hover, .btn-info:active, .btn-info:visited {
    border: none !important;
    background-color: #2E0000 !important;
}

.btn-success, .btn-success:hover, .btn-success:active, .btn-success:visited {
    border: none !important;
    background-color: #D52027 !important;
}

/*Esto es necesario para que los markers salgan justo en el centro. Si no lo pone en el 0,0 del icon*/
.centericon{
    margin-top:-18px;
    margin-left:-9px;
}
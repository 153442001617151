.linktext{
    color:#007bff !important;
    cursor:pointer !important;
}
.linktextUnder{
    color:#007bff !important;
    cursor:pointer !important;
    text-decoration: underline;
}

.addFotos{
    cursor:pointer !important;
    position:absolute;
    bottom:0;
    right: 17px;
    z-index:3999;
}

.addFotosplace{
    font-size: 13px;
    cursor:pointer !important;
    position:absolute;
    bottom:0;
    right: 17px;
    z-index:3999;
    color:#007bff;
}

 

.addFotos label, .addFotosplace label{
    text-decoration: underline;
    margin-left: 2px;
    cursor:pointer !important;
}

.textmodal{
    color:rgba(0, 0, 0, 0.54);
    font-size:13px;
}

.titlegoogle{
    font-family: 'Google Sans',Roboto, Arial,sans-serif;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.75rem;
}
.ratinggoogle{
    letter-spacing: 0.01428571em;
    font-family: Roboto, Arial,sans-serif;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: rgba(0, 0, 0, 0.54);
}
.modalplace{
    width: 100% !important;
}

.customOverlay {
    background: rgba(36, 123, 160, 0.7);
  }
  .customModal {
    background: #b2dbbf;
    max-width: 500px;
    width: 100%;
  }
.listacervezas_container{
    margin-top:15px;
}



.beer-box{
    border:1px solid #888;
    margin-bottom: 5px;
}

/* SLIDERS */
.parent {
    width: 100%;
}
.sliderstyles {
    height: 250px;
    --slider-height-percentage: 100%;
    --slider-transition-duration: 20ms;
    --organic-arrow-thickness: 3px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 30px;
    --organic-arrow-color: #FFC625;
    --control-button-width: 20% !important;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: #FFC625;
    --control-bullet-active-color: #FFC625;
    --loader-bar-color: #FFC625 !important;
    --loader-bar-height: 2px;

}

.sliderstylesbeer {
    height: 200px;
    --slider-height-percentage: 100%;
    --slider-transition-duration: 10ms;
    --organic-arrow-thickness: 3px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 30px;
    --organic-arrow-color: #FFC625;
    --control-button-width: 20% !important;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: #FFC625;
    --control-bullet-active-color: #FFC625;
    --loader-bar-color: #FFC625 !important;
    --loader-bar-height: 2px;

}
/* FIN SLIDER */

.beer_container{
    cursor: pointer;
    margin-top: 5px;
    margin-left: 5px;
    padding:5px;
    background:rgb(255, 255, 255) none repeat scroll 0% 0%;
    border-radius: 3px;
    box-shadow:
    rgba(37, 42, 49, 0.16) 0px 0px 2px 0px,
    rgba(37, 42, 49, 0.12) 0px 1px 4px 0px;
    outline: currentcolor none 0px;
    transition: box-shadow 0.15s ease-in-out 0s;
}

.imagebeer{
    padding:1px;
    width:100%;
}

.smalltext{
    font-size:0.7rem
}
.beer{
    margin: 5px 5px 5px 10px;
    padding:0 0 5px 0 !important;
    cursor: pointer;
}

.comment{
    margin-top: 5px;
    
    position: relative;
    font-size: 12px;
    width:100%;
    
}

.hrC{
    width:'50%';
    margin:20px 0 10px 0;
}

.comments{
    margin-bottom: 10px;
    margin-left: -15px;
}
.commentContent{
    background:rgb(255, 255, 255) none repeat scroll 0% 0%;
    border-radius: 3px;
    box-shadow:
    rgba(37, 42, 49, 0.16) 0px 0px 2px 0px,
    rgba(37, 42, 49, 0.12) 0px 1px 4px 0px;
    outline: currentcolor none 0px;
    transition: box-shadow 0.15s ease-in-out 0s;
    padding:5px 20px 5px 20px;
}

.commenttitle{
    font-family: 'Google Sans',Roboto, Arial,sans-serif;
    font-size: 0.9rem;
    font-weight: 200;
}

.beerextra{
    margin: 5px 5px 5px 10px;
    cursor: default;
}

.beer:hover{
    opacity: .8;
}
.beer-picture{
    width: 20px;
    height: 20px;
    background-size: cover;
    background-position: center;
}

.beertitle{
    font-family: 'Google Sans',Roboto, Arial,sans-serif;
    font-size: 1.1rem;
    font-weight: 200;
    line-height: 20px;
}

.beertitle span{
    margin-left: 15px;
    font-size: 15px;
    cursor: pointer;
}

.sendrating {

  margin-top: 10px;
  background: rgb(245, 247, 249);
  padding: 5px 0;

}

.starsbeer span{
    font-size: 15px;
    cursor:pointer
  }
  
  .starsbeer{
    line-height:2px !important;
    /*padding: 0 1px 0 1px;*/
    margin-top:3px;
  }

  .ratingscomments{
    line-height:2px !important;
    font-size: 12px;
    color:rgba(0, 0, 0, 0.54);
    margin-left: 5px;
  }

  .starsComments span{
    font-size: 12px;
  }
  
  .starsComments{
    line-height:2px !important;
    padding: 0 1px 0 1px;
    margin-top:3px;
  }

  .userComment{
    margin-left:10px;
    font-weight: bold;
  }

  .starsbeer label{
    line-height:18px !important;
    font-size: 12px;
    letter-spacing: 0.01428571em;
    font-family: Roboto, Arial,sans-serif;
    line-height: 1.25rem;
    color: rgba(0, 0, 0, 0.54);
    margin-left: 5px;
    cursor:pointer;
  }

  .starsbeer label:hover{
    text-decoration: underline;
  }

  .iconos span{
    font-size: 12px;
    letter-spacing: 0.04em;
    color: rgba(0, 0, 0, 0.54);
    margin-right:5px;
    margin-left: -2px;
    line-height: 23px;
  }

  .iconos{
      margin-right: -2px;
      margin-left:-20px;
  }

  .iconos span button{
      line-height: 0px;
  }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  display: inline-block;
  height: auto;
  max-width: 100%;
}

.navbar {
  padding: 0px !important;
  height: 50px !important;
}

.nav {
  padding: 0px !important;
}

#content{
  margin-top:80px;
  color:white;
}
.navbar-inner{
  line-height: 0px;
  letter-spacing:2px;
  height: 50px;
}

.navbar-inner li{
  line-height: 30px;
  letter-spacing:2px;
}

.cookies{
  position: absolute;
  bottom:0;
  z-index:20000;
}

.nav-link{
  border-top: 4px solid transparent;
  transition: border-top .3s;  
}
.nav-link:hover{border-top: 4px solid #D52027;}

.titulo{
  text-decoration: none;
  color:black;
}

.titulo span{
  font-size:9px;
  color:#FFC625;
}

.username{
  /*font-size: 0.8rem;
  letter-spacing: 0;*/
  color:black;
}
.username a{
  color:black;
}

.titulo:hover{
  text-decoration: none;
  color:black;
}

.brand{
  letter-spacing:2px;
  border-top: 4px solid white;
  transition: border-top .3s;
}

.brand:hover{border-top: 4px solid #D52027;}

.header{
  background-color: rgba(255,255,255, .95);
  /* remove the gradient */
  background-image: none !important;
  border-bottom:2px solid #FFC625 !important;
  line-height: 40px !important;
  box-shadow: 0 12px 6px -6px rgba(0,0,0, .2) !important;
}

.footer{
background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,.3) 100%); /* W3C */
  /* remove the gradient */
  border: 0 !important;
  box-shadow: none !important;
  
}

.center {
  margin: auto;
  position: absolute;
  left: 0; right: 0;
}

.triangle-down {

  position:fixed;
  margin-top:80px;
  width: 0; 
	height: 0; 
	border-left: 30px solid transparent;
	border-right: 30px solid transparent;
	z-index:10000;
	border-top: 30px solid #D52027;
}

.triangle-down p{  
  text-align:center;
  color:white;
  margin-top:-33px;
  margin-left:-13px;
  transition:color .2s;
  
  
}
.triangle-down:hover{color: rgba(0, 0, 0, .4)}

.threed{
  color:white;
  text-shadow: 0 1px 0 #333,
               0 2px 0 #333,
               0 3px 0 #333,
               0 4px 0 #333,
               0 5px 0 #333,
               0 3px 1px rgba(255,255,255,.1)
}

/* FOOTER */ 
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #fff;
  font-size: 12px;
}